



















































































import { Vue, Component } from 'vue-property-decorator';

import {
  H3Button, H3Input, H3PopupModal, H3List, H3Radio,
} from '@authine/thinking-ui';

import CommonInput from './common-input.vue';

import { LoginService } from '@WorkPlatform/api';

import { RsaEncrypt } from '@WorkPlatform/common/rsg';
import { namespace } from 'vuex-class';
import { parseQuery, setCookie } from '@WorkPlatform/common/utils';
const Global = namespace('Global');

@Component({
  name: 'ChangePassword',
  components: {
    H3Button,
    H3Input,
    CommonInput,
    H3PopupModal,
    H3PopupModalHeader: H3PopupModal.Header,
    H3List,
    H3Radio,
    H3RadioGroup: H3Radio.Group,
  },
})
export default class ChangePassword extends Vue {
  @Global.Action('getAllPerms') getAllPerms!: any;

  hideTenantCodeLessLoginPage = false;

  // 账号
  username: string = '';

  password: string = '';

  submitting: boolean = false;

  usernameShow: boolean = false;

  passwordShow: boolean = false;

  height: number = 0;

  loginError: string = '';

  tenant: any[] = [];

  index: string = '';

  selectedTenantCode: string = '';

  showSelectTenant: boolean = false;

  // 加密公钥
  rsaKey: any = {};

  params: any = {};

  resLoginData: any = null;

  // 是否可以登录
  get submitPassword () {
    return !(this.username && this.password);
  }

  /**
   * 如果是不是admin，则需要获取用户信息之后再跳转到运行时，运行时依赖这些用户信息
   */
  isNotAdminAndRuntimeUser () {
    const redirectUrl: string = this.$route?.query?.redirect_url as string || '';

    if (this.username !== 'admin' && redirectUrl.includes('app_runtime')) {
      return true;
    }

    return false;
  }

  async getSystemConfig () {
    const { success, data } = await LoginService.getLevelPerms();
    if (success) {
      this.hideTenantCodeLessLoginPage = data.hideTenantCodeLessLoginPage;
    }
  }

  /**
   * 登录
   */
  async submit () {
    this.submitting = true;

    try {
      const resKey: any = await LoginService.getKey();

      if (!resKey.success) return;

      const dataKey: any = resKey.data;

      const { index, key } = dataKey;

      this.index = index;

      const rsaPassword: any = RsaEncrypt(this.password, key);

      // rsa加密结束
      this.params = {
        username: this.username,
        password: rsaPassword,
        client_id: 'api',
        index,
      };

      this.login();
    } catch (e) {
      const getErrorStatus = (error: any) =>
        error.httpStatus || (error.response && error.response.status);
      const status: any = getErrorStatus(e);

      if (status === 403) {
        this.loginError = '账号或密码有误';
      }
    } finally {
      this.submitting = false;
      localStorage.removeItem('sessToken');
    };
  }

  async login () {
    const { data: resLogin }: any = await LoginService.login(this.params);
    console.log('🚀 ~ ChangePassword ~ login ~ resLogin:', resLogin);
    if (resLogin.errCode === '600010') {
      this.loginError = '账号或密码有误';
    }
    if (!resLogin.success || !resLogin.data) return;

    this.resLoginData = resLogin.data;
    if (resLogin.data.specification) {
      const self = this;
      localStorage.setItem('sessToken', (resLogin.data as any).access_token);
      this.submitting = false;
      (this.$modal as any).confirm({
        title: '',
        content: '密码不符合规则，请前往修改',
        onConfirm () {
          self.$router.push({
            name: 'm-work-platform-change-password',
            query: {
              userId: resLogin.data.tenants[0]?.userId,
              tenantCode: resLogin.data.tenants[0]?.tenantCode,
            },
          }).catch(() => { });
        },
        onCancel () {
          console.log('onCancel');
        },
      });

      return new Promise(() => { });
    }
    this.setTenant(resLogin.data.tenants);
  }

  setTenant (tenants: any[]) {
    this.tenant = tenants;
    if (this.tenant.length > 1) {
      const url = this.$route.query.redirect_url as string;
      const query = parseQuery(url);
      const tenantCode = query.tenantCode || this.$route.query.tenantCode;
      if (
        tenantCode &&
        tenants.find(item => item.tenantCode === tenantCode)
      ) {
        this.onSelectTenant(query.tenantCode);
        return;
      }
      this.showSelectTenant = true;
    } else {
      this.onSelectTenant(this.tenant[0].tenantCode);
    }
  }

  async onSelectTenant (tenantCode: string) {
    this.getToken(this.resLoginData);

    this.selectedTenantCode = tenantCode;
    localStorage.setItem('tenants', JSON.stringify(this.tenant));
    localStorage.setItem('tenantCode', this.selectedTenantCode);
    sessionStorage.setItem('tenantCode', this.selectedTenantCode);
    setCookie('tenantCode', tenantCode);
    window.onfocus = () => {
      const code = sessionStorage.getItem('tenantCode') || '';
      setCookie('tenantCode', code);
    };
    await this.getLoginInfo();
    await this.getAllPerms();
    this.goPage();
  }

  goPage () {
    const redirectUrl: string = this.$route.query.redirect_url as string;
    if (redirectUrl) {
      window.location.href = redirectUrl.replace('hashsymbol', '#');
    } else {
      this.$router.replace({
        name: 'm-work-platform-home',
      }).catch(() => { });
    }
  }

  /**
   * 获取token
   */
  async getToken (data: any) {
    const token = (data as any).access_token;
    localStorage.setItem('token', token);
    // 登录后，设置cookie
    const expires = new Date(data.expiration);
    setCookie('access_token', token, {
      expires,
      path: '/',
    });
  }

  setInfoToLocal (data: any) {
    enum UnitType {
      Dept = 1,
      Role,
      User,
    }

    const user: any = {
      userInfo: { id: data?.id, name: data?.name, username: data?.username, type: UnitType.User },
      departmentInfo: { id: data?.mainDepartmentId, name: data?.mainDepartmentName, type: UnitType.Dept },
    };
    const userInfo: any = {
      name: data?.name || '-',
      username: data?.username || '-',
      mobile: data?.mobile || '-',
      userId: data.id,
      imgUrl: data?.imgUrl,
    };
    const assetSSO = {
      username: userInfo.username || '-',
      name: userInfo.name || '-',
      mobile: userInfo.mobile || '-',
      client_id: 'api',
      index: this.index,
    };
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
    localStorage.setItem('assetSSO', JSON.stringify(assetSSO));
  };

  async getLoginInfo () {
    LoginService.getLoginInfo().then((res: any) => {
      if (res.success) {
        this.setInfoToLocal(res.data);
      }
    });
  }

  keyDownEnter (event) {
    const e = event || window.event;
    if (e && e.keyCode === 13) {
      if (!this.submitPassword && !this.submitting) {
        this.submit();
      }
    }
  }

  async created () {
    await this.getSystemConfig();
    document.addEventListener('keydown', this.keyDownEnter);
    this.height = document.body.clientHeight - 470;
  }

  destroyed () {
    document.removeEventListener('keydown', this.keyDownEnter);
  }
}
